.title {
	padding: 0;
	color: #132329;
	font-family: 'Montserrat Semibold';
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
	
	@media(min-width: 576px) {
		font-size: 42px;
		line-height: 48px;
	}
	
	@media(min-width: 768px) {
		font-size: 52px;
		line-height: 56px;
	}
	
	@media(min-width: 992px) {
		font-size: 54px;
		line-height: 60px;
	}
	
	@media(min-width: 1200px) {
		font-size: 62px;
		line-height: 66px;
	}
}

.subtitle {
	padding: 0;
	color: #132329;
	font-family: 'Montserrat Semibold';
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
	
	@media(min-width: 576px) {
		font-size: 32px;
		line-height: 44px;
	}
	
	@media(min-width: 768px) {
		font-size: 42px;
		line-height: 52px;
	}
}

.subtitle--smaller {
	padding: 0;
	color: #132329;
	font-family: 'Montserrat Semibold';
	font-size: 24px;
	font-weight: 700;
	line-height: 40px;
	
	@media(min-width: 992px) {
		font-size: 32px;
		line-height: 52px;
	}
}

.title--huge {
	position: relative;
	margin-right: 45px;
	color: #4caf50;
	font-family: 'Montserrat Bold';
	font-size: 122px;
	font-weight: 700;
	line-height: 1;
	
	span {
		position: absolute;
		top: 10px;
		right: -36px;
		font-size: 60px;
	}

	@media(min-width: 576px) {
		margin-right: 90px;
		font-size: 150px;
		
		span {
			right: -50px;
			font-size: 80px;
		}
	}

	@media(min-width: 992px) {
		font-size: 225px;
		
		span {
			font-size: 106px;
		}
	}

	@media(min-width: 1200px) {
		margin-right: 217px;
		font-size: 240px;
		
		span {
			right: -73px;
			font-size: 120px;
		}
	}
}