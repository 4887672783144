.text {
	
	&--font-main {		
		font-family: 'Montserrat Regular';
		font-weight: 500;
	}

	&--font-OSbold {		
		font-family: 'Open Sans Semibold';
		font-weight: 400;
	}

	&--color-main {
		color: #132329;
	}

	&--size-header {
		font-size: 16px;
		line-height: 1;
	}

	&--active {
		margin: 0;
		color: #4baf50;
		font-size: 16px;
		line-height: 1;
		text-transform: uppercase;
		cursor: pointer;
	}
}

.text--phone {
	font-size: 26px;
}

.text__description {
	font-family: 'Open Sans Regular';
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.28px;
	
	&--pad-right {
		padding-right: 10px;
	}
	
	@media(min-width: 400px) {
		font-size: 19px;
		line-height: 29px;
		letter-spacing: 0.32px;
	}
	
	@media(min-width: 768px) {
		font-size: 21px;
		line-height: 32px;
		letter-spacing: 0.42px;
	}
}

.text--modal {
	margin-bottom: 0;
	color: #132329;
	font-family: 'Montserrat Medium';
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	
	@media(min-width: 992px) {
		font-size: 15px;
		line-height: 28px;
	}
}