header {
	padding: 10px 0;
	z-index: 4;
	background: #FFF;
	
	.address,
	.email {
		position: relative;
		padding-left: 25px;
		
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			height: 1em;
		}
	}

	.address {		
		&:before {
			background: url("../img/map-marker.png") no-repeat top right;
			background-size: contain;
		}
	}

	.email {		
		&:before {
			background: url("../img/icon-mail.png") no-repeat top right;
			background-size: contain;
		}
	}

	@media(min-width: 1200px) {
		padding: 20px 0;
	}
}

