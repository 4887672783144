* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
  min-height: 100%;
  position: relative;
  min-width: 320px;
  overflow-x: hidden;
}
/* fix for top margin collapsing and vertical scroll prevent for body tag, when body height = 100% */
body::before {
  content: "";
  display: block;
  width: 1px;
  height: 0px;
  background-color: transparent;
}
input,
textarea {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
  line-height: 1.3;
}
img {
  display: block;
}
a {
  color: #037EC6;
}
code,
pre {
  background-color: #f5f5f5;
  border-radius: 6px;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
  font-family: Consolas, Monaco, Lucida Console, monospace;
  font-size: 1rem;
}
pre {
  color: #666;
  line-height: 1.5;
  margin: 15px 0;
  overflow: auto;
  padding: 10px;
}
code {
  color: #666;
  font-weight: 400;
  padding: .3em .8em;
}
pre code {
  padding: 0;
}
blockquote {
  background: #f7f6f2;
  padding: 30px 80px;
  position: relative;
  width: 100%;
  margin: 2em auto;
  overflow: hidden;
  font-size: 18px;
  line-height: 1.5;
}
blockquote:before {
  content: '“';
  font-weight: 900;
  color: #c4c4c4;
  opacity: 0.2;
  font-size: 4em;
  line-height: 1em;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 0;
}
blockquote p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
  color: #2a2a2a;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
.body--dashboard {
  padding-left: 330px;
  padding-right: 15px;
}
/***style for sticky-footer***/
.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.content {
  flex: 1;
}
.d-flex {
  display: flex;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
@font-face {
  font-family: 'Montserrat Semibold';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.eot');
  src: url('../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'), url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat Light';
  src: url('Montserrat-Light.eot');
  src: url('Montserrat-Light.eot?#iefix') format('embedded-opentype'), url('Montserrat-Light.woff') format('woff'), url('Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat Medium';
  src: url('../fonts/Montserrat/Montserrat-Medium.eot');
  src: url('../fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff'), url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat Regular';
  src: url('../fonts/Montserrat/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'), url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('../fonts/Montserrat/Montserrat-ExtraBold.eot');
  src: url('../fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'), url('../fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Light';
  src: url('OpenSans-Light.eot');
  src: url('OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('OpenSans-Light.woff') format('woff'), url('OpenSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Regular';
  src: url('../fonts/OpenSans/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff'), url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Semibold';
  src: url('../fonts/OpenSans/OpenSans-SemiBold.eot');
  src: url('../fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'), url('../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Bold';
  src: url('OpenSans-Bold.eot');
  src: url('OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('OpenSans-Bold.woff') format('woff'), url('OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
/* Floats */
.float-right {
  float: right;
}
.float-left {
  float: left;
}
/* Text align */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
/* Font weight */
.light {
  font-weight: 300;
}
.normal {
  font-weight: 400;
}
.strong {
  font-weight: 700;
}
/* Flex */
.flex-grow {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-23 {
  margin-top: 23px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-44 {
  margin-top: 44px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-94 {
  margin-top: 94px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-105 {
  margin-top: 105px;
}
.mt-108 {
  margin-top: 108px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-130 {
  margin-top: 130px;
}
.mt-135 {
  margin-top: 135px;
}
.mt-175 {
  margin-top: 175px;
}
.mt-190 {
  margin-top: 190px;
}
.mt-200 {
  margin-top: 200px;
}
.mt-220 {
  margin-top: 220px;
}
.mt-225 {
  margin-top: 225px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 20px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-225 {
  margin-bottom: 225px;
}
.mb-282 {
  margin-bottom: 282px;
}
.mb-290 {
  margin-bottom: 290px;
}
.ml-0 {
  margin-left: 0;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 20px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-85 {
  margin-left: 85px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-100 {
  margin-left: 100px;
}
.mr-0 {
  margin-right: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-85 {
  margin-right: 85px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-95 {
  margin-right: 95px;
}
.mr-100 {
  margin-right: 100px;
}
.section-margin--ml20 {
  margin-left: 0;
}
@media (min-width: 576px) {
  .section-margin--ml20 {
    margin-left: 20px;
  }
}
.section-margin--mt30 {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .section-margin--mt30 {
    margin-top: 45px;
  }
}
@media (min-width: 992px) {
  .section-margin--mt30 {
    margin-top: 30px;
  }
}
.section-margin--mt35 {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .section-margin--mt35 {
    margin-top: 0;
  }
}
.section-margin--mb40 {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .section-margin--mb40 {
    margin-bottom: 40px;
  }
}
.section-margin--mt50 {
  margin-top: 35px;
}
@media (min-width: 1200px) {
  .section-margin--mt50 {
    margin-top: 50px;
  }
}
.section-margin--mt55 {
  margin-top: 0;
}
@media (min-width: 992px) {
  .section-margin--mt55 {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .section-margin--mt55 {
    margin-top: 43px;
  }
}
.section-margin--mb65 {
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .section-margin--mb65 {
    margin-bottom: 65px;
  }
}
.section-margin--mt75 {
  margin-top: 45px;
}
@media (min-width: 1200px) {
  .section-margin--mt75 {
    margin-top: 75px;
  }
}
.section-margin--mt80 {
  margin-top: 35px;
}
@media (min-width: 1200px) {
  .section-margin--mt80 {
    margin-top: 80px;
  }
}
.section-margin--mt85 {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .section-margin--mt85 {
    margin-top: 45px;
  }
}
@media (min-width: 992px) {
  .section-margin--mt85 {
    margin-top: 85px;
  }
}
.section-margin--mt90 {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .section-margin--mt90 {
    margin-top: 70px;
  }
}
@media (min-width: 1200px) {
  .section-margin--mt90 {
    margin-top: 70px;
  }
}
.section-margin--mt110 {
  margin-top: 110px;
}
.section-margin--mt115 {
  margin-top: 85px;
}
@media (min-width: 1200px) {
  .section-margin--mt115 {
    margin-top: 115px;
  }
}
.section-margin--mt120 {
  margin-top: 45px;
}
@media (min-width: 992px) {
  .section-margin--mt120 {
    margin-top: 55px;
  }
}
@media (min-width: 1200px) {
  .section-margin--mt120 {
    margin-top: 115px;
  }
}
.section-margin--mt125 {
  margin-top: 125px;
}
.section-margin--mt130 {
  margin-top: 80px;
}
@media (min-width: 992px) {
  .section-margin--mt130 {
    margin-top: 130px;
  }
}
.section-margin--mt160 {
  margin-top: 35px;
}
@media (min-width: 576px) {
  .section-margin--mt160 {
    margin-top: 70px;
  }
}
@media (min-width: 992px) {
  .section-margin--mt160 {
    margin-top: 100px;
  }
}
@media (min-width: 1200px) {
  .section-margin--mt160 {
    margin-top: 145px;
  }
}
.section-margin--mt170 {
  margin-top: 70px;
}
@media (min-width: 768px) {
  .section-margin--mt170 {
    margin-top: 130px;
  }
}
@media (min-width: 1200px) {
  .section-margin--mt170 {
    margin-top: 170px;
  }
}
.section-margin--mb175 {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .section-margin--mb175 {
    margin-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .section-margin--mb175 {
    margin-bottom: 175px;
  }
}
.section-margin--mt185 {
  margin-top: 70px;
}
@media (min-width: 576px) {
  .section-margin--mt185 {
    margin-top: 125px;
  }
}
@media (min-width: 1200px) {
  .section-margin--mt185 {
    margin-top: 185px;
  }
}
.section-margin--mb210 {
  margin-bottom: 210px;
}
.margin-list:nth-child(n+3) {
  margin-top: 25px;
}
@media (min-width: 992px) {
  .margin-list:nth-child(n+3) {
    margin-top: 0;
  }
  .margin-list:nth-child(n+4) {
    margin-top: 25px;
  }
}
.margin-list1:nth-child(n+2) {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .margin-list1:nth-child(n+2) {
    margin-top: 0;
  }
  .margin-list1:nth-child(n+3) {
    margin-top: 35px;
  }
}
.margin-list2:nth-child(n+2) {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .margin-list2:nth-child(n+2) {
    margin-top: 0;
  }
  .margin-list2:nth-child(n+3) {
    margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .margin-list2:nth-child(n+3) {
    margin-top: 0;
  }
}
.margin-list3:nth-child(n+2) {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .margin-list3:nth-child(n+2) {
    margin-top: 0;
  }
}
.pt-0 {
  padding-top: 0;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-105 {
  padding-top: 105px;
}
.pt-130 {
  padding-top: 130px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-150 {
  padding-top: 150px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-110 {
  padding-bottom: 110px;
}
.pb-114 {
  padding-bottom: 114px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-170 {
  padding-bottom: 170px;
}
.pb-268 {
  padding-bottom: 268px;
}
.pb-265 {
  padding-bottom: 265px;
}
.pl-0 {
  padding-left: 0;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-14 {
  padding-left: 14px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-32 {
  padding-left: 32px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-68 {
  padding-left: 68px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-104 {
  padding-left: 104px;
}
.pl-115 {
  padding-left: 115px;
}
.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 20px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.pr-104 {
  padding-right: 104px;
}
.no-paddings {
  padding: 0;
}
@media (min-width: 992px) {
  .section-padding--pl75 {
    padding-left: 55px;
  }
}
@media (min-width: 1200px) {
  .section-padding--pl75 {
    padding-left: 75px;
  }
}
.section-padding--pb145 {
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .section-padding--pb145 {
    padding-bottom: 90px;
  }
}
@media (min-width: 1200px) {
  .section-padding--pb145 {
    padding-bottom: 145px;
  }
}
.section-padding--pb160 {
  padding-bottom: 160px;
}
.section-padding--pb170 {
  padding-bottom: 90px;
}
@media (min-width: 992px) {
  .section-padding--pb170 {
    padding-bottom: 140px;
  }
}
@media (min-width: 1200px) {
  .section-padding--pb170 {
    padding-bottom: 170px;
  }
}
.section-padding--pb185 {
  padding-bottom: 35px;
}
@media (min-width: 576px) {
  .section-padding--pb185 {
    padding-bottom: 90px;
  }
}
@media (min-width: 768px) {
  .section-padding--pb185 {
    padding-bottom: 135px;
  }
}
@media (min-width: 1200px) {
  .section-padding--pb185 {
    padding-bottom: 185px;
  }
}
.section-padding--pb190 {
  padding-bottom: 140px;
}
@media (min-width: 1200px) {
  .section-padding--pb190 {
    padding-bottom: 190px;
  }
}
.section-padding--pb200 {
  padding-bottom: 90px;
}
@media (min-width: 992px) {
  .section-padding--pb200 {
    padding-bottom: 140px;
  }
}
@media (min-width: 1200px) {
  .section-padding--pb200 {
    padding-bottom: 200px;
  }
}
.section-padding--pb210 {
  padding-bottom: 90px;
}
@media (min-width: 992px) {
  .section-padding--pb210 {
    padding-bottom: 150px;
  }
}
@media (min-width: 1200px) {
  .section-padding--pb210 {
    padding-bottom: 210px;
  }
}
header {
  padding: 10px 0;
  z-index: 4;
  background: #FFF;
}
header .address,
header .email {
  position: relative;
  padding-left: 25px;
}
header .address:before,
header .email:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 1em;
}
header .address:before {
  background: url("../img/map-marker.png") no-repeat top right;
  background-size: contain;
}
header .email:before {
  background: url("../img/icon-mail.png") no-repeat top right;
  background-size: contain;
}
@media (min-width: 1200px) {
  header {
    padding: 20px 0;
  }
}
.nav__wrapper {
  box-shadow: 2px 7px 16px rgba(0, 0, 0, 0.17), 6px 6px 32px rgba(19, 35, 41, 0.4);
}
.nav__wrapper--bg {
  background: #132329;
}
nav,
.nav__mobile {
  height: 52px;
}
@media (min-width: 576px) {
  nav,
  .nav__mobile {
    height: 76px;
  }
}
@media (min-width: 992px) {
  nav,
  .nav__mobile {
    height: 86px;
  }
}
nav {
  position: relative;
}
@media (min-width: 992px) {
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.nav__mobile--open {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 5;
  padding: 0 15px;
  background: #132329;
}
@media (min-width: 576px) {
  .nav__mobile--open {
    padding: 0 10%;
  }
}
.nav__container {
  display: none;
  z-index: 4;
}
.nav__container--open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(19, 35, 41, 0.87);
  z-index: 4;
}
.nav__container--open .nav-link {
  display: inline-block;
  padding: 0;
  color: #FFF;
  font-size: 18px;
}
.nav__container--open .nav-item:not(:first-child) {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .nav__container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .nav__container .nav-item {
    padding: 31px 15px;
    cursor: pointer;
  }
  .nav__container .nav-item:not(:first-child) {
    margin-top: 0;
  }
  .nav__container .nav-item:first-child {
    margin-left: -15px;
  }
  .nav__container .nav-item:hover,
  .nav__container .nav-item:focus,
  .nav__container .nav-item:active {
    background: rgba(255, 255, 255, 0.2);
  }
  .nav__container .nav-item:hover .nav-link:before,
  .nav__container .nav-item:focus .nav-link:before,
  .nav__container .nav-item:active .nav-link:before {
    left: 0;
    width: 100%;
  }
  .nav__container .nav-link {
    position: relative;
    padding: 0;
    color: #FFF;
    font-size: 14px;
  }
  .nav__container .nav-link:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -4px;
    width: 0;
    height: 3px;
    background: #4baf50;
    transition: all 0.3s linear;
  }
}
@media (min-width: 1200px) {
  .nav__container .nav-item {
    padding: 31px 20px;
  }
  .nav__container .nav-link {
    font-size: 16px;
  }
}
.nav-toggler {
  position: relative;
  width: 23px;
  height: 23px;
  overflow: hidden;
}
.nav-toggler__bar {
  position: absolute;
  left: 0;
  top: 4px;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.3s linear;
}
.nav-toggler__bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 70%;
  height: 2px;
  background: #fff;
  transition: all 0.3s linear;
}
.nav-toggler__bar:after {
  content: "";
  position: absolute;
  top: 12px;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.3s linear;
}
.nav-toggler.active .nav-toggler__bar {
  top: 50%;
  transform: rotate(45deg);
}
.nav-toggler.active .nav-toggler__bar:after {
  top: 0;
  transform: rotate(-95deg);
}
.nav-toggler.active .nav-toggler__bar:before {
  left: -100%;
}
.screen-wrapper {
  position: relative;
  padding-top: 1px;
}
.screen-wrapper--bg-bottom {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e3e3e3;
  z-index: -1;
}
.screen-wrapper--bg-floor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 13%;
  background: url("../img/bg-floor.jpg") no-repeat bottom;
  background-size: cover;
  z-index: 2;
}
.screen-wrapper--bg-img {
  position: relative;
  height: 370px;
}
.screen-wrapper--bg-img img {
  position: absolute;
}
.screen-wrapper--bg-img img.door-position {
  bottom: 18%;
  height: 108%;
  left: 12%;
}
.screen-wrapper--bg-img img.pot-position {
  height: 84%;
  bottom: 16%;
  left: 29%;
  z-index: 2;
}
@media (min-width: 450px) {
  .screen-wrapper--bg-img {
    height: 400px;
  }
  .screen-wrapper--bg-img img.door-position {
    left: 18%;
  }
  .screen-wrapper--bg-img img.pot-position {
    left: 42%;
  }
}
@media (min-width: 576px) {
  .screen-wrapper--bg-img img.door-position {
    bottom: 20%;
    left: 24%;
  }
  .screen-wrapper--bg-img img.pot-position {
    bottom: 18%;
  }
}
@media (min-width: 992px) {
  .screen-wrapper--bg-img {
    height: auto;
  }
  .screen-wrapper--bg-img img.door-position {
    bottom: 11%;
    height: 90%;
    left: -15px;
  }
  .screen-wrapper--bg-img img.pot-position {
    height: 70%;
    bottom: 9%;
    left: 32%;
  }
}
@media (min-width: 1200px) {
  .screen-wrapper--bg-img img.door-position {
    height: 107%;
    left: 6px;
  }
  .screen-wrapper--bg-img img.pot-position {
    height: auto;
    left: 43%;
  }
}
.screen-wrapper--bg-light {
  background-color: #eff1f3;
}
.screen-wrapper--bg-colored {
  background-color: #ffffff;
  background-image: linear-gradient(45deg, #4baf50 0%, #92c64e 100%);
}
.screen-wrapper--bg-diler {
  position: relative;
}
.screen-wrapper--bg-diler:before {
  content: "";
  position: absolute;
  width: 140%;
  height: 100%;
  background: url("../img/bg-diler.png") no-repeat;
  background-size: 100% 100%;
}
@media (min-width: 992px) {
  .screen-wrapper--bg-diler:before {
    width: 120%;
  }
}
@media (min-width: 1200px) {
  .screen-wrapper--bg-diler:before {
    height: 110%;
  }
}
.screen-wrapper--bg-dark {
  background: #132329;
}
.bg-img-about {
  position: relative;
}
.bg-img-about img {
  position: absolute;
  z-index: -1;
  bottom: -43%;
  left: -50%;
}
.footer {
  font-family: "Montserrat Regular";
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 2.625;
  font-size: 14px;
}
.footer a {
  color: rgba(255, 255, 255, 0.7);
}
.footer__phone {
  font-size: 22px;
  line-height: 1;
  color: #FFF;
}
@media (min-width: 992px) {
  .footer__phone {
    font-size: 30px;
  }
}
@media (min-width: 1200px) {
  .footer__phone {
    font-size: 32px;
  }
}
.footer__phone-call {
  font-size: 16px;
  color: #4baf50;
  text-transform: uppercase;
  line-height: 3.5;
  cursor: pointer;
}
@media (min-width: 768px) {
  .footer__phone-call {
    line-height: 1;
  }
}
@media (min-width: 992px) {
  .footer__phone-call {
    font-size: 16px;
    line-height: 3.5;
  }
}
.footer__catalog-link:hover,
.footer__catalog-link:focus,
.footer__catalog-link:active {
  color: #FFF;
}
.footer__text-lh1 {
  line-height: 1;
}
@media (min-width: 992px) {
  .footer__text-lh1 {
    line-height: 2.625;
  }
}
.footer__text-lh2 {
  line-height: 2;
}
.footer .copywrite,
.footer .copywrite a {
  color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 1200px) {
  .footer {
    font-size: 16px;
  }
}
.footer--layout {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #fff;
}
@media (min-width: 768px) {
  .footer--layout {
    flex-direction: row;
    justify-content: space-between;
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  .footer--layout {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.advantage-list,
.advantage__help-list {
  list-style-type: none;
  padding-left: 0;
}
.advantage-list--layout,
.advantage__help-list--layout {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .advantage-list--layout,
  .advantage__help-list--layout {
    flex-direction: row;
    justify-content: space-between;
  }
}
.advantage__visual {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 96px;
  padding-bottom: 50px;
}
.advantage__visual:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 8%;
  width: 385px;
  height: 317px;
  background: url(../img/vector1.png) no-repeat;
  background-size: 95% 110%;
  background-position: center;
}
@media (min-width: 576px) {
  .advantage__visual:before {
    top: 5%;
    width: 495px;
    height: 427px;
  }
}
@media (min-width: 768px) {
  .advantage__visual {
    padding-top: 0;
    padding-bottom: 0;
  }
  .advantage__visual:before {
    width: 495px;
    height: 427px;
  }
}
@media (min-width: 992px) {
  .advantage__visual:before {
    width: 635px;
    height: 567px;
  }
}
@media (min-width: 1200px) {
  .advantage__visual:before {
    top: 8%;
    left: 46%;
    width: 733px;
    height: 642px;
  }
}
.advantage__visual-doors {
  padding: 45px 0 55px;
}
.advantage__visual-doors img {
  max-width: 100%;
}
.advantage__visual-text {
  color: #132329;
  margin-left: 20px;
  font-family: 'Montserrat Bold';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  z-index: 1;
}
@media (min-width: 576px) {
  .advantage__visual-text {
    margin-left: 0;
    font-size: 21px;
    line-height: 30px;
  }
}
@media (min-width: 768px) {
  .advantage__visual-text {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 992px) {
  .advantage__visual-text {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (min-width: 1200px) {
  .advantage__visual-text {
    font-size: 32px;
    line-height: 42px;
  }
}
.advantage-item--layout {
  display: flex;
  align-items: flex-end;
}
.advantage-item--layout:not(:last-child) {
  margin-bottom: 30px;
}
@media (min-width: 460px) {
  .advantage-item--layout {
    margin-left: 20%;
  }
}
@media (min-width: 768px) {
  .advantage-item--layout {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .advantage-item--layout:not(:last-child) {
    margin-bottom: 0;
  }
}
.advantage__name {
  padding-left: 10px;
  padding-bottom: 10px;
  color: #132329;
  font-family: 'Montserrat Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
@media (min-width: 576px) {
  .advantage__name {
    padding-left: 20px;
    font-size: 16px;
    line-height: 22px;
  }
}
@media (min-width: 768px) {
  .advantage__name {
    padding-left: 0;
    padding-bottom: 0;
  }
  .advantage__name--align {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .advantage__name {
    font-size: 18px;
    line-height: 24px;
  }
}
.advantage__svg {
  width: 80px;
  height: 80px;
}
@media (min-width: 992px) {
  .advantage__svg {
    width: 100px;
    height: 100px;
  }
}
.advantage__svg-help {
  width: 80px;
  height: 80px;
}
.advantage__svg-help svg {
  width: 80px;
  height: 80px;
}
@media (min-width: 992px) {
  .advantage__svg-help {
    width: 100px;
    height: 100px;
  }
  .advantage__svg-help svg {
    width: 100px;
    height: 100px;
  }
}
.card__wrapper {
  position: relative;
  height: 100%;
  background: #FFF;
  border-radius: 4px;
  box-shadow: 0 13px 100px rgba(35, 49, 74, 0.24), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.1);
  transition: transform 0.4s linear;
}
.card__wrapper--animation:hover,
.card__wrapper--animation:focus,
.card__wrapper--animation:active {
  transform: translateY(-10px);
  box-shadow: 0 22px 106px rgba(35, 49, 74, 0.24), 0 9px 30px rgba(35, 49, 74, 0.1), 0 7px 8px rgba(35, 49, 74, 0.1);
}
.card__wrapper--animation:hover .card__hidden-part,
.card__wrapper--animation:focus .card__hidden-part,
.card__wrapper--animation:active .card__hidden-part {
  opacity: 1;
}
.card__wrapper--fixed-size {
  height: 300px;
}
@media (min-width: 1200px) {
  .card__wrapper--fixed-size {
    height: 370px;
  }
}
.card__wrapper--fixed-size1 {
  height: 340px;
}
@media (min-width: 1200px) {
  .card__wrapper--fixed-size1 {
    height: 370px;
  }
}
.card__wrapper--changed-size {
  width: 270px;
}
@media (min-width: 576px) {
  .card__wrapper--changed-size {
    width: auto;
  }
}
.card__wrapper--slider.slick-slide {
  padding-left: 0;
  padding-right: 20px;
}
.card__wrapper--slider.slick-slide:hover .card__hidden-part {
  opacity: 1;
}
.card--bg-img {
  background-color: #4baf50;
  background-image: linear-gradient(45deg, #4baf50 0%, #92c64e 100%);
}
.card__img {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.card__img--fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.card__img--fixed-size {
  height: 300px;
}
@media (min-width: 1200px) {
  .card__img--fixed-size {
    height: 370px;
  }
}
.card__img--size {
  height: 100%;
  padding: 40px 30px 20px;
}
@media (min-width: 992px) {
  .card__img--size {
    padding: 40px 30px;
  }
}
.card__img--size-bigger {
  min-height: 300px;
}
@media (min-width: 576px) {
  .card__img--size-bigger {
    min-height: 330px;
  }
}
@media (min-width: 1200px) {
  .card__img--size-bigger {
    min-height: 350px;
  }
}
.card__img--pad h3 {
  padding: 32px 10px 0 30px;
}
.card__img--pad h3.pad-smaller {
  padding: 32px 10px 0;
}
.card__img--pad p {
  margin-bottom: 0;
  padding: 0 165px 55px 30px;
}
@media (min-width: 1200px) {
  .card__img--pad h3 {
    padding: 45px 10px 0 30px;
  }
  .card__img--pad h3.pad-smaller {
    padding: 45px 10px 0;
  }
  .card__img--pad p {
    margin-bottom: 0;
    padding: 0 165px 55px 30px;
  }
}
.card__img--position {
  position: absolute;
  max-width: 110%;
  top: 65px;
  left: -5%;
}
@media (min-width: 576px) {
  .card__img--position {
    top: 57px;
    left: 0;
  }
}
@media (min-width: 768px) {
  .card__img--position {
    top: 78px;
    left: -5%;
  }
}
.card__img--pos-abs1 {
  position: absolute;
  top: -29px;
  right: -96px;
  z-index: 1;
}
@media (min-width: 576px) {
  .card__img--pos-abs1 {
    top: -25px;
  }
}
@media (min-width: 768px) {
  .card__img--pos-abs1 {
    top: -14px;
  }
}
@media (min-width: 992px) {
  .card__img--pos-abs1 {
    top: -25px;
  }
}
.card__img--pos-abs2 {
  position: absolute;
  width: 110%;
  top: 34px;
  right: -180px;
  z-index: 1;
}
@media (min-width: 576px) {
  .card__img--pos-abs2 {
    width: 95%;
    top: 2px;
    right: -200px;
  }
}
@media (min-width: 768px) {
  .card__img--pos-abs2 {
    width: 110%;
    top: 41px;
    right: -185px;
  }
}
@media (min-width: 992px) {
  .card__img--pos-abs2 {
    width: 95%;
    top: 42px;
    right: -215px;
  }
}
@media (min-width: 1200px) {
  .card__img--pos-abs2 {
    width: auto;
    top: 17px;
    right: -230px;
  }
}
.card__img--pos-abs3 {
  position: absolute;
  width: 66%;
  top: 6px;
  right: -64px;
  z-index: 1;
}
@media (min-width: 576px) {
  .card__img--pos-abs3 {
    width: 48%;
    right: -42px;
  }
}
@media (min-width: 768px) {
  .card__img--pos-abs3 {
    width: auto;
    right: -83px;
  }
}
@media (min-width: 992px) {
  .card__img--pos-abs3 {
    width: 45%;
    right: -33px;
  }
}
@media (min-width: 1200px) {
  .card__img--pos-abs3 {
    width: auto;
    right: -42px;
  }
}
.card__img--pos-abs4 {
  position: absolute;
  height: 94%;
  top: 34px;
  left: 51%;
  z-index: 1;
}
@media (min-width: 576px) {
  .card__img--pos-abs4 {
    height: 136%;
    top: -43px;
    left: 60%;
  }
}
@media (min-width: 768px) {
  .card__img--pos-abs4 {
    height: 94%;
    top: 49px;
    left: 47%;
  }
}
@media (min-width: 992px) {
  .card__img--pos-abs4 {
    height: auto;
    top: -90px;
    left: 60%;
  }
}
.card__hidden-part {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  background-color: rgba(75, 175, 80, 0.7);
  background-image: linear-gradient(45deg, rgba(75, 175, 80, 0.5) 0%, rgba(146, 198, 78, 0.5) 100%);
  opacity: 0;
  transition: opacity 0.5s ease-in;
}
.card__hidden-part--pad100 {
  padding: 0 40px;
}
@media (min-width: 992px) {
  .card__hidden-part--pad100 {
    padding: 0 100px;
  }
}
.card__hidden-part--pad60 {
  padding: 10px 40px;
}
@media (min-width: 1200px) {
  .card__hidden-part--pad60 {
    padding: 10px 60px;
  }
}
.card__hidden-part--pad15 {
  padding: 40px 15px;
}
.card__title {
  position: relative;
  text-shadow: 1px 3px 16px rgba(0, 0, 0, 0.25), 0 21px 43px rgba(32, 192, 92, 0.4);
  color: #ffffff;
  font-family: 'Montserrat Semibold';
  font-weight: 400;
  z-index: 2;
}
.card__title--smaller {
  font-size: 20px;
  line-height: 30px;
}
@media (min-width: 576px) {
  .card__title--smaller {
    font-size: 23px;
    line-height: 36px;
  }
}
.card__title--bigger {
  font-size: 24px;
  line-height: 28px;
}
@media (min-width: 992px) {
  .card__title--bigger {
    font-size: 36px;
    line-height: 38px;
  }
}
@media (min-width: 1200px) {
  .card__title--bigger {
    font-size: 42px;
    line-height: 36px;
  }
}
.card__text--light {
  position: relative;
  text-shadow: 1px 3px 16px rgba(0, 0, 0, 0.25), 0 21px 43px rgba(32, 192, 92, 0.4);
  color: #ffffff;
  font-family: "Open Sans Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.42px;
  z-index: 2;
}
@media (min-width: 576px) {
  .card__text--light {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (min-width: 1200px) {
  .card__text--light {
    font-size: 21px;
    line-height: 32px;
  }
}
.card__text {
  position: relative;
  padding: 30px 5px;
  color: #132329;
  font-family: 'Montserrat Semibold';
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}
.card__text:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 25%;
  width: 0;
  height: 3px;
  background: #4baf50;
  transition: all 0.5s linear;
}
.card__text:hover:before,
.card__text:focus:before,
.card__text:active:before {
  left: 0;
  width: 100%;
}
@media (min-width: 576px) {
  .card__text {
    font-size: 16px;
    padding: 30px 15px;
  }
}
@media (min-width: 992px) {
  .card__text {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .card__text {
    font-size: 23px;
  }
}
.card--full-width {
  width: 100%;
}
.slider-project,
.slider-project1 {
  position: relative;
  height: 330px;
  box-shadow: 0 13px 100px rgba(35, 49, 74, 0.24), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.1);
}
.slider-project img,
.slider-project1 img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .slider-project,
  .slider-project1 {
    height: 415px;
  }
}
@media (min-width: 1200px) {
  .slider-project,
  .slider-project1 {
    height: 460px;
  }
}
.slider-project2 {
  position: relative;
  height: 220px;
}
.slider-project2 img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
@media (min-width: 400px) {
  .slider-project2 {
    height: 250px;
  }
}
@media (min-width: 500px) {
  .slider-project2 {
    height: 280px;
  }
}
@media (min-width: 576px) {
  .slider-project2 {
    height: 340px;
  }
}
@media (min-width: 768px) {
  .slider-project2 {
    height: 380px;
  }
}
@media (min-width: 992px) {
  .slider-project2 {
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .slider-project2 {
    height: 600px;
  }
}
.slider-partners .slick-slide {
  height: auto;
}
.slick-slide {
  padding-left: 0;
  padding-right: 0;
}
.card__wrapper--slider .slick-arrow,
.modal__slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  box-shadow: 0 13px 100px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.1);
  background-color: #ffffff;
  font-size: 0;
  border: none;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
}
@media (min-width: 992px) {
  .card__wrapper--slider .slick-arrow,
  .modal__slider .slick-arrow {
    width: 56px;
    height: 56px;
  }
}
.card__wrapper--slider .slick-prev:before,
.card__wrapper--slider .slick-next:before,
.modal__slider .slick-prev:before,
.modal__slider .slick-next:before,
.card__wrapper--slider .slick-prev:after,
.card__wrapper--slider .slick-next:after,
.modal__slider .slick-prev:after,
.modal__slider .slick-next:after {
  content: "";
  position: absolute;
  width: 35%;
  height: 2px;
  background: #63b74f;
}
.card__wrapper--slider .slick-prev:before,
.card__wrapper--slider .slick-next:before,
.modal__slider .slick-prev:before,
.modal__slider .slick-next:before {
  top: 18px;
}
.card__wrapper--slider .slick-prev:after,
.card__wrapper--slider .slick-next:after,
.modal__slider .slick-prev:after,
.modal__slider .slick-next:after {
  bottom: 18px;
}
.card__wrapper--slider .slick-prev:focus,
.card__wrapper--slider .slick-next:focus,
.modal__slider .slick-prev:focus,
.modal__slider .slick-next:focus,
.card__wrapper--slider .slick-prev:active,
.card__wrapper--slider .slick-next:active,
.modal__slider .slick-prev:active,
.modal__slider .slick-next:active {
  outline: none;
}
@media (min-width: 992px) {
  .card__wrapper--slider .slick-prev:before,
  .card__wrapper--slider .slick-next:before,
  .modal__slider .slick-prev:before,
  .modal__slider .slick-next:before {
    top: 20px;
  }
  .card__wrapper--slider .slick-prev:after,
  .card__wrapper--slider .slick-next:after,
  .modal__slider .slick-prev:after,
  .modal__slider .slick-next:after {
    bottom: 20px;
  }
}
.card__wrapper--slider .slick-prev,
.modal__slider .slick-prev {
  left: 20px;
}
.card__wrapper--slider .slick-prev:before,
.modal__slider .slick-prev:before {
  right: 35%;
  transform: rotate(-45deg);
}
.card__wrapper--slider .slick-prev:after,
.modal__slider .slick-prev:after {
  right: 35%;
  transform: rotate(45deg);
}
@media (min-width: 992px) {
  .card__wrapper--slider .slick-prev,
  .modal__slider .slick-prev {
    left: 25px;
  }
}
.card__wrapper--slider .slick-next,
.modal__slider .slick-next {
  right: 20px;
}
.card__wrapper--slider .slick-next:before,
.modal__slider .slick-next:before {
  left: 35%;
  transform: rotate(45deg);
}
.card__wrapper--slider .slick-next:after,
.modal__slider .slick-next:after {
  left: 35%;
  transform: rotate(-45deg);
}
@media (min-width: 992px) {
  .card__wrapper--slider .slick-next,
  .modal__slider .slick-next {
    right: 25px;
  }
}
.modal__slider .slick-dots {
  position: absolute;
  margin-bottom: 0;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  cursor: pointer;
}
.slick-dots li:not(:last-child) {
  margin-right: 10px;
}
.slick-dots li {
  position: relative;
  width: 8px;
  height: 8px;
  border: none;
  background: #4BAF50;
}
.slick-dots li button {
  display: none;
}
li.slick-active {
  width: 10px;
  height: 10px;
  border: 2px solid #4BAF50;
  background: transparent;
}
li.slick-active button {
  display: none;
}
.modal-dialog {
  max-width: 720px;
}
@media (min-width: 992px) {
  .modal-dialog {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1140px;
  }
}
.modal__slider-main {
  position: relative;
  width: 100%;
  min-height: 480px;
  height: 480px;
  border-radius: 4px;
}
.modal__slider-main .slick-list,
.modal__slider-main .slick-track {
  height: 100%;
}
@media (min-width: 992px) {
  .modal__slider-main {
    min-height: 560px;
    height: 560px;
  }
}
@media (min-width: 1200px) {
  .modal__slider-main {
    min-height: 592px;
    height: 592px;
  }
}
.modal-body {
  padding: 0;
}
.modal__wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #ffffff;
}
.modal__slider {
  width: 50%;
  height: 100%;
}
.modal__slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal__text {
  position: relative;
  width: 50%;
  padding: 25px 30px;
}
.modal__text p {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .modal__text {
    padding: 35px 50px 30px 50px;
  }
  .modal__text p {
    margin-top: 15px;
  }
}
@media (min-width: 1200px) {
  .modal__text {
    padding: 60px 70px 50px 50px;
  }
  .modal__text p {
    margin-top: 20px;
  }
}
.modal__change-slide span {
  position: relative;
  color: #132329;
  font-family: 'Montserrat Semibold';
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
}
.modal__change-slide span:first-child {
  margin-right: 45px;
}
.modal__change-slide span:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0;
  height: 3px;
  background: #4baf50;
  transition: all 0.5s linear;
}
.modal__change-slide span:hover:before,
.modal__change-slide span:focus:before,
.modal__change-slide span:active:before {
  left: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .modal__change-slide span {
    font-size: 21px;
  }
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  z-index: 2;
}
.close span {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(19, 35, 41, 0.5);
}
.close span:before,
.close span:after {
  content: "";
  position: absolute;
  top: 17%;
  left: 48%;
  width: 2px;
  height: 70%;
  background: rgba(19, 35, 41, 0.5);
}
.close span:before {
  transform: rotate(45deg);
}
.close span:after {
  transform: rotate(-45deg);
}
@media (min-width: 992px) {
  .close {
    width: 35px;
    height: 35px;
  }
  .close:before {
    left: 50%;
  }
}
.text--font-main {
  font-family: 'Montserrat Regular';
  font-weight: 500;
}
.text--font-OSbold {
  font-family: 'Open Sans Semibold';
  font-weight: 400;
}
.text--color-main {
  color: #132329;
}
.text--size-header {
  font-size: 16px;
  line-height: 1;
}
.text--active {
  margin: 0;
  color: #4baf50;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
}
.text--phone {
  font-size: 26px;
}
.text__description {
  font-family: 'Open Sans Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
.text__description--pad-right {
  padding-right: 10px;
}
@media (min-width: 400px) {
  .text__description {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: 0.32px;
  }
}
@media (min-width: 768px) {
  .text__description {
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.42px;
  }
}
.text--modal {
  margin-bottom: 0;
  color: #132329;
  font-family: 'Montserrat Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .text--modal {
    font-size: 15px;
    line-height: 28px;
  }
}
.btn {
  font-family: 'Montserrat Medium';
  background: #4baf50;
  line-height: 1;
  border: none;
  cursor: pointer;
  z-index: 2;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.35);
  background-image: linear-gradient(to top, rgba(75, 175, 80, 0.5) 0%, rgba(146, 198, 78, 0.5) 100%);
  transition: all 0.4s linear;
}
.btn--pad-stand {
  width: 90%;
  padding: 1.4em 2.26em;
}
@media (min-width: 400px) {
  .btn--pad-stand {
    width: 70%;
  }
}
@media (min-width: 768px) {
  .btn--pad-stand {
    padding: 1.4em 3.76em;
    width: auto;
  }
}
.btn--pad-bigger {
  padding: 1.2em 4.28em;
}
.btn--pad-smaller {
  padding: 1.2em 3.46em;
}
.btn--smaller {
  color: #ffffff;
  font-size: 15px;
}
@media (min-width: 1200px) {
  .btn--smaller {
    font-size: 18px;
  }
}
.btn--bigger {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1.2px;
}
@media (min-width: 576px) {
  .btn--bigger {
    font-size: 21px;
  }
}
@media (min-width: 992px) {
  .btn--bigger {
    font-size: 21px;
    letter-spacing: 1.38px;
  }
}
.btn--phone {
  position: relative;
  width: 40px;
  height: 40px;
  padding: 0;
}
.btn--phone:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/icon-phone.png") no-repeat center;
}
.btn--changed-pos {
  position: absolute;
  bottom: -33px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
@media (min-width: 992px) {
  .btn--changed-pos {
    position: static;
    bottom: 0;
    left: 0;
    transform: translateX(0);
  }
}
.btn:hover,
.btn:focus,
.btn:active {
  color: #fff;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0), 0px 8px 31px -6px #4baf50;
  background-image: linear-gradient(to top, rgba(75, 175, 80, 0.5) 0%, rgba(146, 198, 78, 0.5) 100%);
}
.title {
  padding: 0;
  color: #132329;
  font-family: 'Montserrat Semibold';
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
@media (min-width: 576px) {
  .title {
    font-size: 42px;
    line-height: 48px;
  }
}
@media (min-width: 768px) {
  .title {
    font-size: 52px;
    line-height: 56px;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 54px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  .title {
    font-size: 62px;
    line-height: 66px;
  }
}
.subtitle {
  padding: 0;
  color: #132329;
  font-family: 'Montserrat Semibold';
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
@media (min-width: 576px) {
  .subtitle {
    font-size: 32px;
    line-height: 44px;
  }
}
@media (min-width: 768px) {
  .subtitle {
    font-size: 42px;
    line-height: 52px;
  }
}
.subtitle--smaller {
  padding: 0;
  color: #132329;
  font-family: 'Montserrat Semibold';
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
}
@media (min-width: 992px) {
  .subtitle--smaller {
    font-size: 32px;
    line-height: 52px;
  }
}
.title--huge {
  position: relative;
  margin-right: 45px;
  color: #4caf50;
  font-family: 'Montserrat Bold';
  font-size: 122px;
  font-weight: 700;
  line-height: 1;
}
.title--huge span {
  position: absolute;
  top: 10px;
  right: -36px;
  font-size: 60px;
}
@media (min-width: 576px) {
  .title--huge {
    margin-right: 90px;
    font-size: 150px;
  }
  .title--huge span {
    right: -50px;
    font-size: 80px;
  }
}
@media (min-width: 992px) {
  .title--huge {
    font-size: 225px;
  }
  .title--huge span {
    font-size: 106px;
  }
}
@media (min-width: 1200px) {
  .title--huge {
    margin-right: 217px;
    font-size: 240px;
  }
  .title--huge span {
    right: -73px;
    font-size: 120px;
  }
}
.cls-1 {
  fill: #92c64e;
}
.cls-2 {
  fill: #132329;
}
.st0 {
  clip-path: url(#SVGID_2_);
  fill: none;
  stroke: #92C64E;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.st1 {
  clip-path: url(#SVGID_2_);
  fill: none;
  stroke: #92C64E;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke-dasharray: 9.837,9.837;
}
.st2 {
  fill: none;
  stroke: #92C64E;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.st3 {
  clip-path: url(#SVGID_4_);
  fill: none;
  stroke: #92C64E;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke-dasharray: 10,10;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
.position-rel {
  position: relative;
}
.position-static {
  position: static;
}
.flex-grow-2 {
  flex-grow: 2;
}
@media (min-width: 420px) {
  .width-fluid {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 500px) {
  .width-fluid {
    width: 70%;
  }
}
@media (min-width: 576px) {
  .width-fluid {
    width: auto;
  }
}
.width-fluid1 {
  width: 100%;
}
@media (min-width: 380px) {
  .width-fluid1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 470px) {
  .width-fluid1 {
    width: 60%;
  }
}
@media (min-width: 576px) {
  .width-fluid1 {
    width: auto;
  }
}
.width-fluid2 {
  width: 100%;
}
@media (min-width: 400px) {
  .width-fluid2 {
    width: 80%;
  }
}
@media (min-width: 470px) {
  .width-fluid2 {
    width: 70%;
  }
}
@media (min-width: 576px) {
  .width-fluid2 {
    width: auto;
  }
}
