.slider-project,
.slider-project1 {
	position: relative;
	height: 330px;
	box-shadow: 0 13px 100px rgba(35, 49, 74, 0.24), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.1);
	
	img {
		width: 100%;
		min-height: 100%;
		object-fit: cover;
	}
	
	@media(min-width: 768px) {
		height: 415px;
	}

	@media(min-width: 1200px) {
		height: 460px;
	}
}
.slider-project2 {
	position: relative;
	height: 220px;
	
	img {
		width: 100%;
		min-height: 100%;
		object-fit: cover;
	}

	@media(min-width: 400px) {
		height: 250px;
	}

	@media(min-width: 500px) {
		height: 280px;
	}

	@media(min-width: 576px) {
		height: 340px;
	}

	@media(min-width: 768px) {
		height: 380px;
	}

	@media(min-width: 992px) {
		height: 500px;
	}

	@media(min-width: 1200px) {
		height: 600px;
	}
}

.slider-partners .slick-slide {
	height: auto;
}

.slick-list {
}

.slick-slide {
	padding-left: 0;
	padding-right: 0;
}
.card__wrapper--slider .slick-arrow,
.modal__slider .slick-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 48px;
	height: 48px;
	box-shadow: 0 13px 100px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.1);
	background-color: #ffffff;
	font-size: 0;
	border: none;
	border-radius: 50%;
	z-index: 2;
	cursor: pointer;
	
	@media(min-width:992px) {
		width: 56px;
		height: 56px;
	}
}

.card__wrapper--slider .slick-prev,
.card__wrapper--slider .slick-next,
.modal__slider .slick-prev,
.modal__slider .slick-next {
	&:before, &:after {
		content: "";
		position: absolute;
		width: 35%;
		height: 2px;
		background: #63b74f;
	}

	&:before {
		top: 18px;
	}

	&:after {
		bottom: 18px;
	}
	
	&:focus, &:active {
		outline: none;
	}

	@media(min-width:992px) {
		&:before {
			top: 20px;
		}

		&:after {
			bottom: 20px;
		}
	}
}

.card__wrapper--slider .slick-prev,
.modal__slider .slick-prev {
	left: 20px;
	
	&:before {
		right: 35%;
		transform: rotate(-45deg);
	}

	&:after {
		right: 35%;
		transform: rotate(45deg);
	}

	@media(min-width:992px) {
		left: 25px;
	}
}

.card__wrapper--slider .slick-next,
.modal__slider .slick-next {
	right: 20px;
	
	&:before {
		left: 35%;
		transform: rotate(45deg);
	}

	&:after {
		left: 35%;
		transform: rotate(-45deg);
	}

	@media(min-width:992px) {
		right: 25px;
	}
}

.modal__slider .slick-dots {
	position: absolute;
	margin-bottom: 0;
	bottom: 25px;
	left: 50%;
	transform: translateX(-50%);
}

.slick-dots {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 0;
	list-style-type: none;
	font-size: 0;
	cursor: pointer;
	
	li:not(:last-child) {
		margin-right: 10px;
	}
	
	li  {
		position: relative;
		width: 8px;
		height: 8px;		
		border: none;
		background: #4BAF50;		
	}

	li button {
		display: none;		
	}
}

li.slick-active {
	width: 10px;
	height: 10px;
	border: 2px solid #4BAF50;
	background: transparent;
		
	button {
		display: none;
	}
}