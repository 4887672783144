.btn {
	// position: relative;
	font-family: 'Montserrat Medium';
	background: #4baf50;
	line-height: 1;
	border: none;	
	cursor: pointer;
	z-index: 2;
	box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.35);
	background-image: linear-gradient(to top, rgba(75, 175, 80, 0.5) 0%, rgba(146, 198, 78, 0.5) 100%);
	transition: all 0.4s linear;
	
	&--pad-stand {
		width: 90%;
		padding: 1.4em 2.26em;
		
		@media(min-width: 400px) {
			width: 70%;
		}
		
		@media(min-width: 768px) {
			padding: 1.4em 3.76em;
			width: auto;
		}
	}

	&--pad-bigger {
		padding: 1.2em 4.28em;
	}

	&--pad-smaller {
		padding: 1.2em 3.46em;
		
	}

	&--smaller {		
		color: #ffffff;
		font-size: 15px;
		
		@media(min-width: 1200px) {
			font-size: 18px;
		}
	}

	&--bigger {		
		color: #ffffff;
		font-size: 18px;
		letter-spacing: 1.2px;
		
		@media(min-width: 576px) {
			font-size: 21px;
		}
		
		@media(min-width: 992px) {
			font-size: 21px;
			letter-spacing: 1.38px;
		}
	}

	&--phone {
		position: relative;
		width: 40px;
		height: 40px;
		padding: 0;
		
		&:before {
			content: "";
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			background: url("../img/icon-phone.png") no-repeat center;
		}
	}

	&--changed-pos {
		position: absolute;
		bottom: -33px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
		
		@media(min-width: 992px) {
			position: static;
			bottom: 0;
			left: 0;
			transform: translateX(0);
		}
	}

	&:hover, &:focus, &:active {
		color: #fff;
		box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0), 0px 8px 31px -6px rgba(75,175,80,1);
		background-image: linear-gradient(to top, rgba(75, 175, 80, 0.5) 0%, rgba(146, 198, 78, 0.5) 100%);
	}
}
