.modal-dialog {
	max-width: 720px;

	@media(min-width: 992px) {
		max-width: 960px;
	}

	@media(min-width: 1200px) {
		max-width: 1140px;
	}
}

.modal__slider-main {
	position: relative;
	width: 100%;
	min-height: 480px;
	height: 480px;
	border-radius: 4px;
	
	.slick-list,
	.slick-track {
		height: 100%;
	}

	@media(min-width: 992px) {
		min-height: 560px;
		height: 560px;
	}

	@media(min-width: 1200px) {
		min-height: 592px;
		height: 592px;
	}
}
.modal-body {
	padding: 0;
}
.modal__wrapper {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-color: #ffffff;
}
.modal__slider {
	width: 50%;
	height: 100%;
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	

}
.modal__text {
	position: relative;
	width: 50%;
	padding: 25px 30px;
	
	p {
		margin-top: 15px;
	}

	@media(min-width: 992px) {
		padding: 35px 50px 30px 50px;
	
		p {
			margin-top: 15px;
		}
	}

	@media(min-width: 1200px) {
		padding: 60px 70px 50px 50px;
		
		p {
			margin-top: 20px;
		}
	}
}

.modal__change-slide {

	span {
		position: relative;
		color: #132329;
		font-family: 'Montserrat Semibold';
		font-weight: 400;
		font-size: 16px;		
		line-height: 1;
		cursor: pointer;
		
		&:first-child {
			margin-right: 45px;
		}
		
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -10px;
			width: 0;
			height: 3px;
			background: #4baf50;
			transition: all 0.5s linear;
		}

		&:hover, &:focus, &:active {
			&:before {
				left: 0;
				width: 100%;
			}
		}
	}

	@media(min-width: 992px) {
		span {
			font-size: 21px;
		}		
	}
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 25px;
	height: 25px;
	z-index: 2;
	
	span {
		display: inline-block;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 2px solid rgba(19, 35, 41, 0.5);
		
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 17%;
			left: 48%;
			width: 2px;
			height: 70%;
			background: rgba(19, 35, 41, 0.5);
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	@media(min-width: 992px) {
		width: 35px;
		height: 35px;
		
		&:before {
			left: 50%;
		}
	}
}