.advantage-list,
.advantage__help-list {
	list-style-type: none;
	padding-left: 0;
	
	&--layout {
		display: flex;
		flex-direction: column;
		
		@media(min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
		}
	}
}

.advantage__visual {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 96px;
	padding-bottom: 50px;
	
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 8%;
		width: 385px;
		height: 317px;
		background: url(../img/vector1.png) no-repeat;
		background-size: 95% 110%;
		background-position: center;
	}

	// @media(min-width: 450px) {
				
	// 	&:before {
	// 		width: 455px;
	// 		height: 387px;
	// 	}
	// }

	@media(min-width: 576px) {		
		&:before {
			top: 5%;
			width: 495px;
			height: 427px;
		}
	}

	@media(min-width: 768px) {
		padding-top: 0;
		padding-bottom: 0;
		
		&:before {
			width: 495px;
			height: 427px;
		}
	}

	@media(min-width: 992px) {
		
		&:before {
			width: 635px;
			height: 567px;
		}
	}

	@media(min-width: 1200px) {
		&:before {
			top: 8%;
			left: 46%;
			width: 733px;
			height: 642px;
		}
	}
}

.advantage__visual-doors {
	padding: 45px 0 55px;
	
	img {
		max-width: 100%;
	}
}

.advantage__visual-text {
	color: #132329;
	margin-left: 20px;
	font-family: 'Montserrat Bold';
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	z-index: 1;
	
	@media(min-width: 576px) {
		margin-left: 0;
		font-size: 21px;
		line-height: 30px;
	}
	
	@media(min-width: 768px) {
		font-size: 24px;
		line-height: 32px;
	}
	
	@media(min-width: 992px) {
		font-size: 30px;
		line-height: 40px;
	}
	
	@media(min-width: 1200px) {
		font-size: 32px;
		line-height: 42px;
	}
}

.advantage-item {
	
	&--layout {
		display: flex;
		align-items: flex-end;
		
		&:not(:last-child) {
			margin-bottom: 30px;
		}

		@media(min-width: 460px) {
			margin-left: 20%;
		}
		
		@media(min-width: 768px) {
			flex-direction: column;
			align-items: center;
			margin-left: 0;
			
			&:not(:last-child) {
				margin-bottom: 0;
			}			
		}
	}
}

.advantage__name {
	padding-left: 10px;
	padding-bottom: 10px;
	color: #132329;
	font-family: 'Montserrat Medium';
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
			
	@media(min-width: 576px) {
		padding-left: 20px;
		font-size: 16px;
		line-height: 22px;
	}
	
	@media(min-width: 768px) {
		padding-left: 0;
		padding-bottom: 0;		
		
		&--align {
			text-align: center;
		}
	}

	@media(min-width: 1200px) {
		font-size: 18px;
		line-height: 24px;
	}
}

.advantage__svg {
	width: 80px;
	height: 80px;
	
	@media(min-width: 992px) {
		width: 100px;
		height: 100px;
	}
}

.advantage__svg-help {
	width: 80px;
	height: 80px;
	
	svg {
		width: 80px;
		height: 80px;
	}
	
	@media(min-width: 992px) {
		width: 100px;
		height: 100px;
		
		svg {
			width: 100px;
			height: 100px;
		}
	}
}