.screen-wrapper {
	position: relative;
	padding-top: 1px;
	
	&--bg-bottom {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;right: 0;
		background: #e3e3e3;
		z-index: -1;
	}

	&--bg-floor {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 13%;
		background: url("../img/bg-floor.jpg") no-repeat bottom;
		background-size: cover;
		z-index: 2;
	}

	&--bg-img {
		position: relative;
		height: 370px;
		
		img {
			position: absolute;		
			
			&.door-position {
				bottom: 18%;
				height: 108%;
				left: 12%;
			}

			&.pot-position {
				height: 84%;
				bottom: 16%;
				left: 29%;
				z-index: 2;
			}
		}

		@media(min-width: 450px) {
			height: 400px;
			
			img {
				
				&.door-position {
					left: 18%;
				}

				&.pot-position {
					left: 42%;
				}
			}
		}

		@media(min-width: 576px) {
			
			img {	
				&.door-position {
					bottom: 20%;
					left: 24%;
				}

				&.pot-position {
					bottom: 18%;
				}
			}
		}

		@media(min-width: 992px) {
			height: auto;
			
			img {	
				
				&.door-position {
					bottom: 11%;
					height: 90%;
					left: -15px;
				}

				&.pot-position {
					height: 70%;
					bottom: 9%;
					left: 32%;
				}
			}
		}

		@media(min-width: 1200px) {			

			img {
				&.door-position {
					height: 107%;
					left: 6px;
				}

				&.pot-position {
					height: auto;
					left: 43%;
				}
			}			
		}
	}	

	&--bg-light {
		background-color: #eff1f3;
	}

	&--bg-colored {
		background-color: #ffffff;
		background-image: linear-gradient(45deg, #4baf50 0%, #92c64e 100%);
	}

	&--bg-diler {
		position: relative;
		
		&:before {
			content: "";
			position: absolute;
			width: 140%;
			height: 100%;
			background: url("../img/bg-diler.png") no-repeat;
			background-size: 100% 100%;
		}

		@media(min-width: 992px) {
			&:before {
				width: 120%;
			}
		}

		@media(min-width: 1200px) {
			&:before {
				height: 110%;
			}
		}
	}

	&--bg-dark {
		background: #132329;
	}
}

.bg-img-about {
	position: relative;
	
	img {
		position: absolute;
		z-index: -1;
		bottom: -43%;
		left: -50%;
	}
}