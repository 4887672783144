.nav__wrapper {
	box-shadow: 2px 7px 16px rgba(0, 0, 0, 0.17), 6px 6px 32px rgba(19, 35, 41, 0.4);
	
	&--bg {
		background: #132329;
	}
}
nav,
.nav__mobile {
	height: 52px;
	
	@media(min-width: 576px) {
		height: 76px;
	}
	
	@media(min-width: 992px) {
		height: 86px;
	}
}

nav {
	position: relative;

	@media(min-width: 992px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}	
}

.nav__mobile--open {
	position: fixed;
	left: 0;
	width: 100%;
	z-index: 5;
	padding: 0 15px;
	background: #132329;
	
	@media(min-width: 576px) {
		padding: 0 10%;
	}
}

.nav__container {
	display: none;
	z-index: 4;
	
	&--open {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(19, 35, 41, .87);
		z-index: 4;
				
		.nav-link {
			display: inline-block;
			padding: 0;
			color: #FFF;
			font-size: 18px;
		}

		.nav-item {
			&:not(:first-child) {
				margin-top: 30px;
			}
		}
	}

	@media(min-width: 992px) {
		display: flex;
		position: relative;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		
		.nav-item {
			padding: 31px 15px;
			cursor: pointer;
			
			&:not(:first-child) {
				margin-top: 0;
			}

			&:first-child {
				margin-left: -15px;
			}			

			&:hover, &:focus, &:active {
				background: rgba(255, 255, 255, 0.2);
				
				.nav-link:before {
					left: 0;
					width: 100%;
				}
			}

		}

		.nav-link {
			position: relative;
			padding: 0;
			color: #FFF;
			font-size: 14px;
			
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				bottom: -4px;
				width: 0;
				height: 3px;
				background: #4baf50;
				transition: all 0.3s linear;
			}
		}
	}

	@media(min-width: 1200px) {

		.nav-item {
			padding: 31px 20px;
		}

		.nav-link {			
			font-size: 16px;
		}				
	}
}

.nav-toggler {
  position: relative;
  width: 23px;
  height: 23px;
  overflow: hidden;
    
  &__bar {
    position: absolute;
    left: 0;
    top: 4px;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: all 0.3s linear;
    
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      width: 70%;
      height: 2px;
      background: #fff;
      transition: all 0.3s linear;
    }
    
    &:after {
      content: "";
      position: absolute;
      top: 12px;
      width: 100%;
      height: 2px;
      background: #fff;
      transition: all 0.3s linear;
    }
  }
  
  &.active .nav-toggler__bar {
    top: 50%;
    transform: rotate(45deg);
  }
  
  &.active .nav-toggler__bar:after {    
    top: 0;
    transform: rotate(-95deg);
  }
  
  &.active .nav-toggler__bar:before {
    left: -100%;
  }
}