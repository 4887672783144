@font-face {
	font-family: 'Montserrat Semibold';
	src: url('../fonts/Montserrat/Montserrat-SemiBold.eot');
	src: url('../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
	url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Light';
	src: url('Montserrat-Light.eot');
	src: url('Montserrat-Light.eot?#iefix') format('embedded-opentype'),
	url('Montserrat-Light.woff') format('woff'),
	url('Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Medium';
	src: url('../fonts/Montserrat/Montserrat-Medium.eot');
	src: url('../fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
	url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Regular';
	src: url('../fonts/Montserrat/Montserrat-Regular.eot');
	src: url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
	url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Bold';
	src: url('../fonts/Montserrat/Montserrat-ExtraBold.eot');
	src: url('../fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
	url('../fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans Light';
	src: url('OpenSans-Light.eot');
	src: url('OpenSans-Light.eot?#iefix') format('embedded-opentype'),
	url('OpenSans-Light.woff') format('woff'),
	url('OpenSans-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans Regular';
	src: url('../fonts/OpenSans/OpenSans-Regular.eot');
	src: url('../fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
	url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans Semibold';
	src: url('../fonts/OpenSans/OpenSans-SemiBold.eot');
	src: url('../fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
	url('../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans Bold';
	src: url('OpenSans-Bold.eot');
	src: url('OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
	url('OpenSans-Bold.woff') format('woff'),
	url('OpenSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	}