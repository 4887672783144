.pt-0 {	padding-top: 0; } 
.pt-5 {	padding-top: 5px; } 
.pt-10 { padding-top: 10px; } 
.pt-15 { padding-top: 15px; } 
.pt-20 { padding-top: 20px; } 
.pt-25 { padding-top: 25px; }
.pt-30 { padding-top: 30px; } 
.pt-35 { padding-top: 35px; }
.pt-40 { padding-top: 40px; } 
.pt-45 { padding-top: 45px; }
.pt-50 { padding-top: 50px; } 
.pt-55 { padding-top: 55px; }
.pt-60 { padding-top: 60px; } 
.pt-65 { padding-top: 65px; }
.pt-70 { padding-top: 70px; } 
.pt-75 { padding-top: 75px; }
.pt-80 { padding-top: 80px; } 
.pt-85 { padding-top: 85px; }
.pt-90 { padding-top: 90px; } 
.pt-95 { padding-top: 95px; }
.pt-100 { padding-top: 100px; }  
.pt-105 { padding-top: 105px; }  
.pt-130 { padding-top: 130px; }  
.pt-140 { padding-top: 140px; }  
.pt-150 { padding-top: 150px; }  

.pb-0 {	padding-bottom: 0; } 
.pb-5 {	padding-bottom: 5px; } 
.pb-10 { padding-bottom: 10px; } 
.pb-15 { padding-bottom: 15px; } 
.pb-20 { padding-bottom: 20px; } 
.pb-25 { padding-bottom: 25px; }
.pb-30 { padding-bottom: 30px; } 
.pb-35 { padding-bottom: 35px; }
.pb-40 { padding-bottom: 40px; } 
.pb-45 { padding-bottom: 45px; }
.pb-50 { padding-bottom: 50px; } 
.pb-55 { padding-bottom: 55px; }
.pb-60 { padding-bottom: 60px; } 
.pb-65 { padding-bottom: 65px; }
.pb-70 { padding-bottom: 70px; } 
.pb-75 { padding-bottom: 75px; }
.pb-80 { padding-bottom: 80px; } 
.pb-85 { padding-bottom: 85px; }
.pb-90 { padding-bottom: 90px; } 
.pb-95 { padding-bottom: 95px; }
.pb-100 { padding-bottom: 100px; } 
.pb-110 { padding-bottom: 110px; } 
.pb-114 { padding-bottom: 114px; } 
.pb-120 { padding-bottom: 120px; } 
.pb-150 { padding-bottom: 150px; } 
.pb-160 { padding-bottom: 160px; } 
.pb-170 { padding-bottom: 170px; } 
.pb-268 { padding-bottom: 268px; } 
.pb-265 { padding-bottom: 265px; } 

.pl-0 {	padding-left: 0; } 
.pl-5 {	padding-left: 5px; } 
.pl-10 { padding-left: 10px; } 
.pl-14 { padding-left: 14px; } 
.pl-15 { padding-left: 15px; } 
.pl-20 { padding-left: 20px; } 
.pl-25 { padding-left: 25px; }
.pl-30 { padding-left: 30px; } 
.pl-32 { padding-left: 32px; } 
.pl-35 { padding-left: 35px; }
.pl-40 { padding-left: 40px; } 
.pl-45 { padding-left: 45px; }
.pl-50 { padding-left: 50px; } 
.pl-55 { padding-left: 55px; }
.pl-60 { padding-left: 60px; } 
.pl-65 { padding-left: 65px; }
.pl-68 { padding-left: 68px; }
.pl-70 { padding-left: 70px; } 
.pl-75 { padding-left: 75px; }
.pl-80 { padding-left: 80px; } 
.pl-85 { padding-left: 85px; }
.pl-90 { padding-left: 90px; } 
.pl-95 { padding-left: 95px; }
.pl-100 { padding-left: 100px; } 
.pl-104 { padding-left: 104px; } 
.pl-115 { padding-left: 115px; } 

.pr-0 {	padding-right: 0; } 
.pr-5 {	padding-right: 5px; } 
.pr-10 { padding-right: 10px; } 
.pr-15 { padding-right: 15px; } 
.pr-20 { padding-right: 20px; } 
.pr-25 { padding-right: 25px; }
.pr-30 { padding-right: 20px; } 
.pr-35 { padding-right: 35px; }
.pr-40 { padding-right: 40px; } 
.pr-45 { padding-right: 45px; }
.pr-50 { padding-right: 50px; } 
.pr-55 { padding-right: 55px; }
.pr-60 { padding-right: 60px; } 
.pr-65 { padding-right: 65px; }
.pr-70 { padding-right: 70px; } 
.pr-75 { padding-right: 75px; }
.pr-80 { padding-right: 80px; } 
.pr-85 { padding-right: 85px; }
.pr-90 { padding-right: 90px; } 
.pr-95 { padding-right: 95px; }
.pr-100 { padding-right: 100px; } 
.pr-104 { padding-right: 104px; } 
.no-paddings { padding:0; }

.section-padding {

	&--pl75 {
		
		@media(min-width: 992px) {
			padding-left: 55px;
		}
		
		@media(min-width: 1200px) {
			padding-left: 75px;
		}
	}

	&--pb145 {
		padding-bottom: 40px;
		
		@media(min-width: 992px) {
			padding-bottom: 90px;
		}
		
		@media(min-width: 1200px) {
			padding-bottom: 145px;
		}
	}

	&--pb160 {
		padding-bottom: 160px;
	}

	&--pb170 {
		padding-bottom: 90px;
		
		@media(min-width: 992px) {
			padding-bottom: 140px;
		}
		
		@media(min-width: 1200px) {
			padding-bottom: 170px;
		}
	}

	&--pb185 {
		padding-bottom: 35px;
		
		@media(min-width: 576px) {
			padding-bottom: 90px;
		}
		
		@media(min-width: 768px) {
			padding-bottom: 135px;
		}
		
		@media(min-width: 1200px) {
			padding-bottom: 185px;
		}
	}

	&--pb190 {
		padding-bottom: 140px;
		
		@media(min-width: 1200px) {
			padding-bottom: 190px;
		}
	}

	&--pb200 {
		padding-bottom: 90px;
		
		@media(min-width: 992px) {
			padding-bottom: 140px;
		}
		
		@media(min-width: 1200px) {
			padding-bottom: 200px;
		}
	}

	&--pb210 {
		padding-bottom: 90px;
		
		@media(min-width: 992px) {
			padding-bottom: 150px;
		}

		@media(min-width: 1200px) {
			padding-bottom: 210px;
		}
	}	
}