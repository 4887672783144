.footer {
	font-family: "Montserrat Regular";
	font-weight: 400;
	color: rgba(255, 255, 255, 0.7);
	line-height: 2.625;
	font-size: 14px;
	
	a {
		color: rgba(255, 255, 255, 0.7);
	}
	
	&__phone {
		font-size: 22px;
		line-height: 1;
		color: #FFF;
		
		@media(min-width: 992px) {
			font-size: 30px;
		}
		
		@media(min-width: 1200px) {
			font-size: 32px;
		}
	}

	&__phone-call {
		font-size: 16px;
		color: rgb(75, 175, 80);
		text-transform: uppercase;
		line-height: 3.5;
		cursor: pointer;
		
		@media(min-width: 768px) {
			line-height: 1;
		}
		
		@media(min-width: 992px) {
			font-size: 16px;
			line-height: 3.5;
		}
	}

	&__catalog-link {
		&:hover, &:focus, &:active {
			color: #FFF;
		}
	}

	&__text-lh1 {
		line-height: 1;
		
		@media(min-width: 992px) {
			line-height: 2.625;
		}
	}

	&__text-lh2 {
		line-height: 2;
	}

	.copywrite,
	.copywrite a {
		color: rgba(255, 255, 255, 0.5);
	}

	@media(min-width: 1200px) {
		font-size: 16px;
	}

	&--layout {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #fff;
		
		@media(min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
			border-bottom: none;
		}
		
		@media(min-width: 992px) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}
}