.mt-0 {	margin-top: 0; } 
.mt-5 {	margin-top: 5px; } 
.mt-10 { margin-top: 10px; } 
.mt-15 { margin-top: 15px; } 
.mt-20 { margin-top: 20px; } 
.mt-23 { margin-top: 23px; }
.mt-25 { margin-top: 25px; }
.mt-28 { margin-top: 28px; }
.mt-30 { margin-top: 30px; } 
.mt-35 { margin-top: 35px; }
.mt-40 { margin-top: 40px; } 
.mt-42 { margin-top: 42px; } 
.mt-44 { margin-top: 44px; } 
.mt-45 { margin-top: 45px; }
.mt-50 { margin-top: 50px; } 
.mt-55 { margin-top: 55px; }
.mt-60 { margin-top: 60px; } 
.mt-65 { margin-top: 65px; }
.mt-70 { margin-top: 70px; } 
.mt-75 { margin-top: 75px; }
.mt-80 { margin-top: 80px; } 
.mt-85 { margin-top: 85px; }
.mt-90 { margin-top: 90px; } 
.mt-94 { margin-top: 94px; } 
.mt-95 { margin-top: 95px; }
.mt-100 { margin-top: 100px; } 
.mt-105 { margin-top: 105px; } 
.mt-108 { margin-top: 108px; } 
.mt-110 { margin-top: 110px; } 
.mt-130 { margin-top: 130px; }
.mt-135 { margin-top: 135px; }
.mt-175 { margin-top: 175px; }
.mt-190 { margin-top: 190px; }
.mt-200 { margin-top: 200px; }
.mt-220 { margin-top: 220px; }
.mt-225 { margin-top: 225px; }


.mb-0 {	margin-bottom: 0; } 
.mb-5 {	margin-bottom: 5px; } 
.mb-10 { margin-bottom: 10px; } 
.mb-15 { margin-bottom: 15px; } 
.mb-20 { margin-bottom: 20px; } 
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 20px; } 
.mb-35 { margin-bottom: 35px; }
.mb-40 { margin-bottom: 40px; } 
.mb-45 { margin-bottom: 45px; }
.mb-50 { margin-bottom: 50px; } 
.mb-55 { margin-bottom: 55px; }
.mb-60 { margin-bottom: 60px; } 
.mb-65 { margin-bottom: 65px; }
.mb-70 { margin-bottom: 70px; } 
.mb-75 { margin-bottom: 75px; }
.mb-80 { margin-bottom: 80px; } 
.mb-85 { margin-bottom: 85px; }
.mb-90 { margin-bottom: 90px; } 
.mb-95 { margin-bottom: 95px; }
.mb-100 { margin-bottom: 100px; }
.mb-225 { margin-bottom: 225px; }
.mb-282 { margin-bottom: 282px; }
.mb-290 { margin-bottom: 290px; }

.ml-0 {	margin-left: 0; } 
.ml-5 {	margin-left: 5px; } 
.ml-10 { margin-left: 10px; } 
.ml-15 { margin-left: 15px; } 
.ml-20 { margin-left: 20px; } 
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 20px; } 
.ml-35 { margin-left: 35px; }
.ml-40 { margin-left: 40px; } 
.ml-45 { margin-left: 45px; }
.ml-50 { margin-left: 50px; } 
.ml-55 { margin-left: 55px; }
.ml-60 { margin-left: 60px; } 
.ml-65 { margin-left: 65px; }
.ml-70 { margin-left: 70px; } 
.ml-75 { margin-left: 75px; }
.ml-80 { margin-left: 80px; } 
.ml-85 { margin-left: 85px; }
.ml-90 { margin-left: 90px; } 
.ml-95 { margin-left: 95px; }
.ml-100 { margin-left: 100px; } 

.mr-0 {	margin-right: 0; } 
.mr-5 {	margin-right: 5px; } 
.mr-10 { margin-right: 10px; } 
.mr-15 { margin-right: 15px; } 
.mr-20 { margin-right: 20px; } 
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; } 
.mr-35 { margin-right: 35px; }
.mr-40 { margin-right: 40px; } 
.mr-45 { margin-right: 45px; }
.mr-50 { margin-right: 50px; } 
.mr-55 { margin-right: 55px; }
.mr-60 { margin-right: 60px; } 
.mr-65 { margin-right: 65px; }
.mr-70 { margin-right: 70px; } 
.mr-75 { margin-right: 75px; }
.mr-80 { margin-right: 80px; } 
.mr-85 { margin-right: 85px; }
.mr-90 { margin-right: 90px; } 
.mr-95 { margin-right: 95px; }
.mr-100 { margin-right: 100px; } 

.section-margin {

	&--ml20 {
		margin-left: 0;
		
		@media(min-width: 576px) {
			margin-left: 20px;
		}
	}

	&--mt30 {
		margin-top: 35px;
		
		@media(min-width: 768px) {
			margin-top: 45px;
		}
		
		@media(min-width: 992px) {
			margin-top: 30px;
		}
	}

	&--mt35 {
		margin-top: 35px;
		
		@media(min-width: 768px) {
			margin-top: 0;
		}
	}

	&--mb40 {
		margin-bottom: 30px;
		
		@media(min-width: 992px) {
			margin-bottom: 40px;
		}
	}

	&--mt50 {
		margin-top: 35px;
		
		@media(min-width: 1200px) {
			margin-top: 50px;
		}
	}

	&--mt55 {
		margin-top: 0;
		
		@media(min-width: 992px) {
			margin-top: 30px;
		}
		
		@media(min-width: 1200px) {
			margin-top: 43px;
		}
	}

	&--mb65 {
		margin-bottom: 35px;
		
		@media(min-width: 576px) {
			margin-bottom: 65px;
		}
	}

	&--mt75 {
		margin-top: 45px;
		
		@media(min-width: 1200px) {
			margin-top: 75px;
		}
	}

	&--mt80 {
		margin-top: 35px;
		
		@media(min-width: 1200px) {
			margin-top: 80px;
		}
	}

	&--mt85 {
		margin-top: 35px;
		
		@media(min-width: 768px) {
			margin-top: 45px;
		}
		
		@media(min-width: 992px) {
			margin-top: 85px;
		}
	}

	&--mt90 {
		margin-top: 30px;
		
		@media(min-width: 576px) {
			margin-top: 70px;		
		}
		
		@media(min-width: 1200px) {
			margin-top: 70px;		
		}
	}

	&--ml95 {
		
		@media(min-width: 470px) {
		}
		
		@media(min-width: 768px) {
		}
		
		@media(min-width: 992px) {
		}
	}

	&--mt110 {
		margin-top: 110px;
	}

	&--mt115 {
		margin-top: 85px;
		
		@media(min-width: 1200px) {
			margin-top: 115px;
		}
	}

	&--mt120 {
		margin-top: 45px;
		
		@media(min-width: 992px) {
			margin-top: 55px;
		}
		
		@media(min-width: 1200px) {
			margin-top: 115px;
		}
	}

	&--mt125 {
		margin-top: 125px;
	}

	&--mt130 {
		margin-top: 80px;
		
		@media(min-width: 992px) {
			margin-top: 130px;
		}
	}

	&--mt160 {
		margin-top: 35px;
		
		@media(min-width: 576px) {
			margin-top: 70px;
		}
		
		@media(min-width: 992px) {
			margin-top: 100px;
		}
		
		@media(min-width: 1200px) {
			margin-top: 145px;
		}
	}

	&--mt170 {
		margin-top: 70px;
		
		@media(min-width: 768px) {
			margin-top: 130px;
		}
		
		@media(min-width: 1200px) {
			margin-top: 170px;
		}
	}

	&--mb175 {
		margin-bottom: 0;
		
		@media(min-width: 992px) {
			margin-bottom: 120px;
		}
		
		@media(min-width: 1200px) {
			margin-bottom: 175px;
		}
	}

	&--mt185 {
		margin-top: 70px;
		
		@media(min-width: 576px) {
			margin-top: 125px;
		}
		
		@media(min-width: 1200px) {
			margin-top: 185px;
		}
	}

	&--mb210 {
		margin-bottom: 210px;
	}


}

.margin-list {
	&:nth-child(n+3) {
		margin-top: 25px;
	}

	@media(min-width: 992px) {
		&:nth-child(n+3) {
			margin-top: 0;
		}

		&:nth-child(n+4) {
			margin-top: 25px;
		}
	}
}

.margin-list1 {
	&:nth-child(n+2) {
		margin-top: 35px;
	}

	@media(min-width: 768px) {
		&:nth-child(n+2) {
			margin-top: 0;
		}

		&:nth-child(n+3) {
		margin-top: 35px;
	}
	}
}

.margin-list2 {
	&:nth-child(n+2) {
		margin-top: 35px;
	}

	@media(min-width: 768px) {
		&:nth-child(n+2) {
			margin-top: 0;
		}

		&:nth-child(n+3) {
			margin-top: 35px;
		}
	}

	@media(min-width: 992px) {

		&:nth-child(n+3) {
			margin-top: 0;
		}
	}
}

.margin-list3 {
	&:nth-child(n+2) {
		margin-top: 35px;
	}

	@media(min-width: 768px) {
		&:nth-child(n+2) {
			margin-top: 0;
		}
	}
}