// Base
@import "base/_base.less";
@import "base/_fonts.less";
@import "base/_variables.less";
@import "base/_mixins.less";

// Utils
@import "utils/_utils.less";
@import "utils/_margins.less";
@import "utils/_paddings.less";


// Sections
@import "sections/_header.less";
@import "sections/_nav.less";
@import "sections/_screen-wrapper.less";
@import "sections/_footer.less";

// Blocks
@import "blocks/_advantage.less";
@import "blocks/_card.less";
@import "blocks/_slider-main.less";
@import "blocks/_modal-projects.less";

// Elements
@import "elements/_text.less";
@import "elements/_button.less";
@import "elements/_title.less";
@import "elements/_svg.less";


.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

a {
	&:hover, &:focus, &:active {
		text-decoration: none;
		color: inherit;
	}
}

.position-rel {
	position: relative;
}

.position-static {
	position: static;
}

.flex-grow-2 {
	flex-grow: 2;
}

.width-fluid {

	@media(min-width: 420px) {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	@media(min-width: 500px) {
		width: 70%;
	}

	@media(min-width: 576px) {
		width: auto;
	}
}

.width-fluid1 {
	width: 100%;
	
	@media(min-width: 380px) {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	@media(min-width: 470px) {
		width: 60%;
	}

	@media(min-width: 576px) {
		width: auto;
	}
}

.width-fluid2 {
	width: 100%;
	
	@media(min-width: 400px) {
		width: 80%;
	}

	@media(min-width: 470px) {
		width: 70%;
	}

	@media(min-width: 576px) {
		width: auto;
	}
}