.card__wrapper {
	position: relative;
	height: 100%;
	background: #FFF;
	border-radius: 4px;
	box-shadow: 0 13px 100px rgba(35, 49, 74, 0.24), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.1);
	transition: transform 0.4s linear;
	
	&--animation {
		&:hover, &:focus, &:active {
		transform: translateY(-10px);
		box-shadow: 0 22px 106px rgba(35, 49, 74, 0.24), 0 9px 30px rgba(35, 49, 74, 0.1), 0 7px 8px rgba(35, 49, 74, 0.1);
		
			.card__hidden-part {
				opacity: 1;
			}
		}
	}

	&--fixed-size {
		height: 300px;
		
		@media(min-width: 1200px) {
			height: 370px;
		}
	}

	&--fixed-size1 {
		height: 340px;
		
		@media(min-width: 1200px) {
			height: 370px;
		}
	}

	&--changed-size {
		width: 270px;
		
		@media(min-width: 576px) {
			width: auto;
		}
	}

	&--slider.slick-slide {
		padding-left: 0;
		padding-right: 20px;
		
		&:hover {
			.card__hidden-part {
				opacity: 1;
			}
		}
	}
}

.card {
	
	&--bg-img {
		background-color: #4baf50;
		background-image: linear-gradient(45deg, #4baf50 0%, #92c64e 100%);
	}
}

.card__img {
	position: relative;
	width: 100%;
	overflow: hidden;

	&--fit {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&--fixed-size {
		height: 300px;
		
		@media(min-width: 1200px) {
			height: 370px;
		}
	}

	&--size {
		height: 100%;
		padding: 40px 30px 20px;
		
		@media(min-width: 992px) {
			padding: 40px 30px;
		}
	}

	&--size-bigger {
		min-height: 300px;
		
		@media(min-width: 576px) {
			min-height: 330px;
		}
		
		@media(min-width: 1200px) {
			min-height: 350px;
		}
	}

	&--pad {
		h3 {
			padding: 32px 10px 0 30px;
			
			&.pad-smaller {
				padding: 32px 10px 0;
			}
		}
		p {
			margin-bottom: 0;
			padding: 0 165px 55px 30px;
		}
		
		@media(min-width: 1200px) {
			h3{
				padding: 45px 10px 0 30px;
			
				&.pad-smaller {
					padding: 45px 10px 0;
				}
			}
			p {
				margin-bottom: 0;
				padding: 0 165px 55px 30px;
			}			
		}
	}

	&--position {
		position: absolute;
		max-width: 110%;
		top: 65px;
		left: -5%;
		
		@media(min-width: 576px) {
			top: 57px;
			left: 0;
		}

		@media(min-width: 768px) {
			top: 78px;
			left: -5%;
		}
	}

	&--pos-abs1 {
		position: absolute;
		top: -29px;
		right: -96px;
		z-index: 1;
		
		@media(min-width: 576px) {
			top: -25px;
		}
		
		@media(min-width: 768px) {
			top: -14px;
		}
		
		@media(min-width: 992px) {
			top: -25px;
		}
	}

	&--pos-abs2 {
		position: absolute;
		width: 110%;
		top: 34px;
		right: -180px;
		z-index: 1;
		
		@media(min-width: 576px) {
			width: 95%;
			top: 2px;
			right: -200px;
		}

		@media(min-width: 768px) {
			width: 110%;
			top: 41px;
			right: -185px;
		}
		
		@media(min-width: 992px) {
			width: 95%;
			top: 42px;
			right: -215px;
		}
		
		@media(min-width: 1200px) {
			width: auto;
			top: 17px;
			right: -230px;
		}
	}

	&--pos-abs3 {
		position: absolute;
		width: 66%;
		top: 6px;
		right: -64px;
		z-index: 1;
		
		@media(min-width: 576px) {
			width: 48%;
			right: -42px;
		}
		
		@media(min-width: 768px) {
			width: auto;
			right: -83px;
		}
		
		@media(min-width: 992px) {
			width: 45%;
			right: -33px;
		}
		
		@media(min-width: 1200px) {
			width: auto;
			right: -42px;
		}
	}

	&--pos-abs4 {
		position: absolute;
		height: 94%;
		top: 34px;
		left: 51%;
		z-index: 1;
		
		@media(min-width: 576px) {
			height: 136%;
			top: -43px;
			left: 60%;
		}
		
		@media(min-width: 768px) {
			height: 94%;
			top: 49px;
			left: 47%;
		}
		
		@media(min-width: 992px) {
			height: auto;
			top: -90px;
			left: 60%;
		}
	}
}

.card__hidden-part {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px 4px 0 0;
	background-color: rgba(75, 175, 80, 0.7);
	background-image: linear-gradient(45deg, rgba(75, 175, 80, 0.5) 0%, rgba(146, 198, 78, 0.5) 100%);
	opacity: 0;
	transition: opacity 0.5s ease-in;
}

.card__hidden-part--pad100 {
	padding: 0 40px;
	
	@media(min-width: 992px) {
		padding: 0 100px;
	}
}

.card__hidden-part--pad60 {
	padding: 10px 40px;
	
	@media(min-width: 1200px) {
		padding: 10px 60px;
	}
}

.card__hidden-part--pad15 {
	padding: 40px 15px;
}

.card__title {
	position: relative;
	text-shadow: 1px 3px 16px rgba(0, 0, 0, 0.25), 0 21px 43px rgba(32, 192, 92, 0.4);
	color: #ffffff;
	font-family: 'Montserrat Semibold';	
	font-weight: 400;
	z-index: 2;

	&--smaller {
		font-size: 20px;
		line-height: 30px;
		
		@media(min-width: 576px) {
			font-size: 23px;
			line-height: 36px;
		}
	}

	&--bigger {
		font-size: 24px;
		line-height: 28px;
		
		@media(min-width: 992px) {
			font-size: 36px;
			line-height: 38px;
		}
		
		@media(min-width: 1200px) {
			font-size: 42px;
			line-height: 36px;
		}
	}
}


.card__text--light {
	position: relative;
	text-shadow: 1px 3px 16px rgba(0, 0, 0, 0.25), 0 21px 43px rgba(32, 192, 92, 0.4);
	color: #ffffff;
	font-family: "Open Sans Regular";
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.42px;
	z-index: 2;
	
	@media(min-width: 576px) {
		font-size: 18px;
		line-height: 30px;
	}
	
	@media(min-width: 1200px) {
		font-size: 21px;
		line-height: 32px;
	}
}

.card__text {	
	position: relative;
	padding: 30px 5px;
	color: #132329;
	font-family: 'Montserrat Semibold';
	font-size: 15px;
	font-weight: 400;
	line-height: 1;
	
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 25%;
		width: 0;
		height: 3px;
		background: #4baf50;
		transition: all 0.5s linear;
	}

	&:hover, &:focus, &:active {
		&:before {
			left: 0;
			width: 100%;
		}
	}

	@media(min-width: 576px) {
		font-size: 16px;
		padding: 30px 15px;
	}

	@media(min-width: 992px) {
		font-size: 22px;
	}

	@media(min-width: 1200px) {
		font-size: 23px;
	}
}

.card--full-width {
	width: 100%;
}